<template>
  <div class="enroll" v-if="!state.success && state.canEnroll">
    <div class="enroll-bg">
      <div class="title">{{ $t('EnrollmentInfor') }}</div>
    </div>
    <div class="main">
      <div class="change-icon" @click="handleSwitch">
        <SwapOutlined class="icon" />
        {{ state.showHistory ? $t('ParticipationRegistration') : $t('HistoricalEnrollmentInformationSearch') }}
      </div>
      <a-spin v-show="!state.showHistory" :spinning="state.loading">
        <div class="detail">
          <div class="title">{{ state.info.enrollName }}</div>
          <div class="time" v-if="state.info.isLimit">
            <ClockCircleOutlined />
            {{ dateFormat(state.info.limitStartTime) }}
            ~
            {{ dateFormat(state.info.limitEndTime) }}
          </div>
          <div class="intro" v-html="state.info.content"></div>
        </div>
        <div class="container">
          <collect v-if="state.fieldLen" layout="vertical" ref="collectRef" />
          <questionnaire v-if="state.questionLen" ref="questionnaireRef" />
        </div>
        <div class="submit-btn">
          <a-button size="large" :loading="loading" :disabled="state.disabled" type="primary" @click="submit">
            {{ state.btnTip || $t('XB_Signup') }}
          </a-button>
        </div>
      </a-spin>
      <div class="history" v-show="state.showHistory">
        <div class="detail">
          <div class="title">{{ $t('HistoricalEnrollmentInformationSearch') }}</div>
        </div>
        <div class="query">
          <a-form
            ref="queryFormRef"
            :model="queryForm"
            :rules="rules"
            layout="vertical"
          >
            <a-form-item :label="$t('IDNumber')" name="idCard">
              <a-input
                v-model:value="queryForm.idCard"
                :maxlength="18"
                :placeholder="$t('LB_QA_PleaseEnter') + $t('IDNumber')"
              />
            </a-form-item>
            <a-form-item :label="$t('login.mobile')" name="phone">
              <a-input
                v-model:value="queryForm.phone"
                :maxlength="11"
                :placeholder="$t('login.mobile_p')"
              />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" size="large" @click="handleQuery()">{{ $t('Query') }}</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="result" v-show="state.isQuery">
          <div class="result-title">{{ $t('QueryResult') }}:</div>
          <div class="result-table" v-if="state.historyList.length">
            <a-table
              size="small"
              :dataSource="state.historyList"
              :columns="state.columns"
              :pagination="pagination"
              :rowKey="(index) => index"
              @change="changeTable"
            >
              <template #sort="{ index }">
                {{ index + 1 }}
              </template>
              <template #time="{ text }">
                {{ dateFormat(text) }}
              </template>
              <template #status="{ text, record }">
                <span class="green" v-if="text === 1">{{ record.enrollStatusName }}</span>
                <span class="red" v-else-if="text === 2">{{ record.enrollStatusName }}</span>
                <span class="orange" v-else>{{ record.enrollStatusName }}</span>
              </template>
            </a-table>
          </div>
          <div class="no-data" v-else>
            <a-empty
              :image="require('@/assets/image/no_data_3.png')"
              :image-style="{ height: '186px' }"
              style="padding: 30px 0"
            >
              <template #description>
                <span style="color: #999">{{ $t("NoRecordOfEligibleEnrollment") }}</span>
              </template>
            </a-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="success" v-if="state.success">
    <CheckCircleOutlined class="icon" />
    <div class="text">
      {{ $t('EnrollSuccessTip') }}
    </div>
    <div class="action">
      <div class="action-item">
        <a-button type="primary" block @click="handleContinue">{{ $t('ContinueToEnroll') }}</a-button>
      </div>
      <div class="action-item">
        <a-button class="search" type="primary" block @click="handleSearch">{{ $t('EnrollInfoSearch') }}</a-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance, computed, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getEnrollDetail, submitEnroll, queryEnrollHistory } from "@/api/noAuth";
import collect from "./collect.vue";
import questionnaire from "@/views/questionnaire/detail.vue";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const store = useStore();
const route = useRoute();
const langActive = computed(() => store.getters.lang);
const state = reactive({
  id: Number(route.query.id || 0),
  customerId: Number(route.query.customerId || 0),
  info: {},
  loading: false,
  success: false,
  canEnroll: false,
  locale: "zh_CN",
  fieldLen: 0,
  questionLen: 0,
  btnTip: '',
  disabled: false,
  showHistory: false,
  isQuery: false,
  historyList: [],
  columns: [
    {
      title: $t('practice.serial_number'),
      dataIndex: 'index',
      width: 80,
      slots: { customRender: 'sort' },
    },
    {
      title: $t('Pub_Lab_UserName'),
      dataIndex: 'enrollPersonnelName',
      ellipsis: true,
    },
    {
      title: $t('IDNumber'),
      dataIndex: 'enrollPersonnelAccount',
      ellipsis: true,
    },
    {
      title: $t('login.mobile'),
      dataIndex: 'enrollPersonnelPhone',
      ellipsis: true,
    },
    {
      title: $t('EnrollmentCourse'),
      dataIndex: 'enrollCourseName',
      ellipsis: true,
    },
    {
      title: $t('EnrollmentTime'),
      dataIndex: 'enrollCreateTime',
      ellipsis: true,
      width: 150,
      slots: { customRender: 'time' },
    },
    {
      title: $t('EnrollmentResult'),
      dataIndex: 'enrollStatus',
      ellipsis: true,
      slots: { customRender: 'status' },
    },
  ],
  num: 1,
});
const collectRef = ref(null);
const questionnaireRef = ref(null);
async function getDetail() {
  state.fieldLen = 0;
  state.questionLen = 0;
  const params = {
    cId: state.customerId,
    enrollId: state.id,
  };
  const res = await getEnrollDetail(params);
  // state.locale = langActive.value.replace(/-/, "_");
  if (res.ret === 0) {
    state.canEnroll = true;
    const { fields, questions, ...data } = res.data;
    if (data.isLimit) {
      const current = Date.parse(new Date()) / 1000;
      const isStart = current >= data.limitStartTime;
      const isEnd = current <= data.limitEndTime;
      if (!isStart) state.btnTip = $t('CM_StartedYet');
      if (!isEnd) state.btnTip = $t('XB_Signup') + $t('XB_HasEnded');
      state.btnTip && proxy.$message.info(state.btnTip)
      state.disabled = !(isStart && isEnd);
    }
    state.info = data;
    const fieldsTemp = fields || [];
    const fieldList = fieldsTemp.map((item) => {
      const { memberField, ...col } = item;
      memberField.dataRequired = col.dataRequired;
      memberField.tipContent = col.tipContent;
      const langName = JSON.parse(col.langName);
      memberField.langName = langName;
      if (langName[state.locale]) {
        memberField.defaultName = memberField.fieldName;
        memberField.fieldName = langName[state.locale];
      }
      return memberField;
    });
    const questionsTemp = questions || [];
    questionsTemp.forEach((item) => {
      item.questionId = item.enrollQuestionId;
    });
    state.fieldLen = fieldList.length;
    state.questionLen = questionsTemp.length;
    nextTick(() => {
      collectRef.value && collectRef.value.getDetail(fieldList);
      questionnaireRef.value && questionnaireRef.value.getDetail(questionsTemp);
    })
  } else {
    if (res.data.status === 5) {
      return proxy.$message.error($t("XB_Signup") + $t("not_exist"));
    }
    if (res.data.status === 1) {
      return proxy.$message.error(
        $t("NotWithinTheTimeframe", { type: $t("XB_Signup") })
      );
    }
    proxy.$message.error(res.msg);
  }
}
getDetail();
async function submit() {
  state.success = true;
  let fields = null;
  let questions = null;
  let options = [];
  if (collectRef.value) fields = await collectRef.value.getResult();
  if (questionnaireRef.value) questions = await questionnaireRef.value.getResult();
  
  if (Array.isArray(fields)) {
    if (!fields.length) return;
    fields.forEach((item) => {
      item.fieldSource = 1;
      if (item.defaultName) {
        item.fieldName = item.defaultName;
        delete item.defaultName;
      }
    });
    options = fields;
  };
  if (Array.isArray(questions)) {
    if (!questions.length) return;
    questions.forEach((item) => {
      let arr = [];
      item.scoreList.forEach((op) => {
        if (op.score === 1) {
          let str = op.option
            ? `${op.option}${op.remark ? "/" + op.remark : ""}`
            : op.remark;
          arr.push(str);
        }
      });
      const p = {
        fieldKey: item.questionID + "",
        fieldName: item.title,
        fieldValue: arr.join(","),
        fieldSource: 2,
        fieldType: item.questionType,
      };
      options.push(p);
    });
  };

  if (!options.length) return;
  const params = {
    cId: state.customerId,
    enrollId: state.id,
    options: options,
  };
  state.loading = true;
  const res = await submitEnroll(params);
  if (res.ret === 0) {
    proxy.$message.success($t("XB_SignUpSuccessfully"));
    state.success = true;
    state.canEnroll = false;
  }
  state.loading = false;
}

/**
 * 
 * 历史查询
 */
function handleSwitch() {
  state.showHistory = !state.showHistory;
  if (!state.showHistory) {
    getDetail();
  }
}
const queryFormRef = ref(null);
const queryForm = reactive({
  idCard: '',
  phone: '',
});
const rules = {
  idCard: [
    { required: true, message: $t('LB_QA_PleaseEnter') + $t('IDNumber'), trigger: 'change' },
    { min: 15, max: 18, message: $t('PleaseEnterYourCorrectIDCard'), trigger: 'change' },
  ],
  phone: [
    { required: true, message: $t('login.mobile_p'), trigger: 'change' },
    { min: 11, message: $t('PleaseEnterTheCorrectCellPhoneNumber'), trigger: 'change' },
  ],
};
function handleQuery() {
  queryFormRef.value
    .validate()
    .then(async () => {
      const params = {
        ...queryForm,
        cId: state.customerId,
        page: pagination.current,
        pageSize: pagination.pageSize,
      }
      const res = await queryEnrollHistory(params);
      if (res.ret === 0) {
        proxy.$message.success($t('CM_Success'));
        state.historyList = res.data.list || [];
        pagination.total = res.data.totals;
      } else {
        proxy.$message.error(res.msg);
      }
      state.isQuery = true;
    })
    .catch((error) => {
      console.log(error);
    });
};
const pagination = reactive({
  current: 1,
  pageSize: 10,
  total: 0,
})
function changeTable(page) {
  pagination.current = page.page;
  pagination.pageSize = page.pageSize;
  handleQuery();
};
function resetQuery() {
  pagination.current = 1;
  pagination.pageSize = 10;
  pagination.total = 0;
  state.historyList = [];
  state.isQuery = false;
  state.showHistory = true;
}

function handleContinue() {
  state.success = false;
  getDetail();
};
function handleSearch() {
  state.success = false;
  resetQuery();
};
</script>
<style lang="less" scoped>
.enroll {
  padding-bottom: 40px;
}
.enroll-bg {
  position: relative;
  height: 200px;
  background-color: #4996fe;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 200px;
    background: #4996fe url("~@/assets/image/collect-bg.png") no-repeat;
    background-size: contain;
  }
  .title {
    position: absolute;
    top: 25%;
    right: 41%;
    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.main {
  position: relative;
  .mixinWrap(800px);
  .change-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(110%);
    font-size: 18px;
    color: var(--theme);
    cursor: pointer;
    .icon {
      font-size: 20px;
    }
  }
  .detail {
    margin: 30px 0;
    .title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
    }
    .time {
      margin: 10px 0;
      color: #666;
      text-align: center;
    }
  }
  .container {
    ::v-deep(.content) {
      width: 800px;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .submit-btn {
    margin-top: 20px;
    text-align: center;
  }
  .query {
    text-align: center;
  }
  .result {
    &-title {
      font-size: 16px;
    }
    &-table {
      margin: 10px 0;
      .green {
        color: #19be6b
      }
      .red {
        color: red;
      }
      .orange {
        color: orange;
      }
    }
    .no-data {
      margin: 10px auto;
    }
  }
}
.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #19be6b;
  .icon {
    font-size: 80px;
  }
  .text {
    width: 60%;
    margin: 15px auto;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  .action {
    display: flex;
    width: 240px;
    margin: 0 auto;
    &-item {
      flex: 1;
      padding: 0 10px;
      .search {
        background-color: #d58e0a;
        border-color: #d58e0a;
      }
    }
  }
}
</style>
